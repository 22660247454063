import React, { Component } from 'react';
import './ScoreStar.css';

class ScoreStar extends Component {
  render() {
    return (
      <React.Fragment>
        <input name="score" id={this.props.rightId} type="radio" value={this.props.rightVal} 
          onClick={() => this.props.setScore(this.props.rightVal)} 
          defaultChecked={this.props.rightChecked}></input>
        <label title={this.props.rightVal} className={this.props.starClass} 
          htmlFor={this.props.rightId} 
          onMouseEnter={() => this.props.setTempScore(this.props.rightVal)}
          onMouseLeave={() => this.props.resetTempScore()}></label>
        <input name="score" id={this.props.leftId} type="radio" value={this.props.leftVal} 
          onClick={() => this.props.setScore(this.props.leftVal)} 
          defaultChecked={this.props.leftChecked}></input>
        <label title={this.props.leftVal} className={this.props.starClass + " half-star"} 
          htmlFor={this.props.leftId} 
          onMouseEnter={() => this.props.setTempScore(this.props.leftVal)} 
          onMouseLeave={() => this.props.resetTempScore()}></label>
      </React.Fragment>
    );
  };
}

export default ScoreStar;