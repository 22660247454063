import React, { Component } from 'react';
import './App.css';
import ScoreBoard from './ScoreBoard';
import NotFound from './NotFound';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import withRoot from './withRoot';

class App extends Component {
  render () {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={ScoreBoard} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
};

export default withRoot(App);
