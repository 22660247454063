import React, { Component } from 'react';

import './ScoreFooter.css';

import { injectIntl, FormattedMessage } from 'react-intl';

class ScoreFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth
    };
  }

  handleWindowSizeChange = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  render() {
    const { windowWidth } = this.state;
    const isMobile = windowWidth <= 500;
    const footerHeight = isMobile ? "160" : "120";

    return (
      <React.Fragment>
        <footer className="footer" style={{minHeight: footerHeight + 'px'}}>
          <img src={process.env.PUBLIC_URL + '/img/logo_blanco.png'} alt="RCO" height="60" width="156"/>
          <span className="footer-content">
            <FormattedMessage 
              id="agree_with_terms"
              defaultMessage="By accessing this website yo agree to the following "/>
            <a className="footer-link" href="https://redviacorta.mx/es/terms">
              <FormattedMessage 
                id="terms_of_service"
                defaultMessage="Terms of Service "/>
            </a>
            <FormattedMessage 
              id="terms_of_service_concatenation"
              defaultMessage="and the "/>
            <a className="footer-link" href="https://redviacorta.mx/es/policy">
              <FormattedMessage 
                id="privacy_notice"
                defaultMessage="Privacy Notice"/>
            </a>
          </span>
          <span className="footer-content">
            <FormattedMessage 
               id="rco_all_rights_reserved"
               defaultMessage="© 2019 Red Vía Corta - All rights reserved"/>
          </span>
        </footer>
      </React.Fragment>
    );
  }; 
}

export default injectIntl(ScoreFooter);