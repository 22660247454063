import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
// css
import './ScoreComments.css'
// react-intl imports
import { injectIntl, FormattedMessage } from 'react-intl';

class ScoreComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: ''
    }
  }

  updateComment(comment) {
    this.setState({
      comment: comment
    });
    this.props.commentListener(comment);
  }

  render() {
    const { formatMessage } = this.props.intl;
    const commentsHelperText = formatMessage({id: 'comments_helper_text'});
    return (
      <div className="comment-field-container">
        <hr className="comment-separator"/>
        <span className="comment-label">
        <FormattedMessage 
            id="comment_label" 
            defaultMessage="Share your opinion"
            />
        </span>
        <FormattedMessage 
          id="comment_placeholder" 
          defaultMessage="We want to hear you!">
          {
            placeholder =>  <TextField 
              placeholder={placeholder}
              helperText={commentsHelperText} 
              multiline 
              rows="10" 
              variant="outlined" 
              margin="normal" 
              fullWidth 
              onChange={(event) => this.updateComment(event.target.value)}/>
          }
        </FormattedMessage>
      </div>
    );
  }
}

export default injectIntl(ScoreComment);