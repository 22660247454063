import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import ReactDOM from 'react-dom';
import './ScoreResultModal.css';
// react-intl imports
import { FormattedMessage } from 'react-intl';  

class ScoreResultModal extends Component {

  render() {
    return ReactDOM.createPortal(
      <div className={this.props.show ? "modal-container display" : "modal-container hide"}>
      <section className="modal-main">
        <p className="modal-legend">{this.props.text}</p>
        <img className={this.props.loading ? "modal-image display" : "modal-image hide"} src={require('./Spinner-1s-100px.gif')} alt=""/>
        <Button className={this.props.loading ? "close-button hide" : "close-button display"} 
                color="secondary"
                variant="contained"
                onClick={() => this.props.showResultModal(false)}>
          <FormattedMessage 
            id="close_result_modal"
            defaultMessage="Close"
            />
        </Button>
      </section> 
      </div>, 
      document.body);
  }
};

export default ScoreResultModal;