import React from 'react';
import ScoreComponent from './ScoreComponent';
import ScoreStar from './ScoreStar';
// css
import './ScoreStarList.css'

class ScoreStarList extends ScoreComponent {
  constructor(props) {
    super(props);
    this.state.tempScore = this.props.startScore;
  }

  updateTempScore(newTempScore) {
    this.setState({
      tempScore: newTempScore
    });
  }

  resetTempScore() {
    this.setState({
      tempScore: this.props.getScore()
    });
  }

  renderStar(leftVal, leftId, rightVal, rightId, starClass, checked) {
    return <ScoreStar setScore={this.updateScore.bind(this)} 
                      setTempScore={this.updateTempScore.bind(this)} 
                      resetTempScore={this.resetTempScore.bind(this)}
                      leftVal={leftVal} 
                      leftId={leftId} 
                      leftChecked={leftVal === this.state.score} 
                      rightVal={rightVal} 
                      rightId={rightId} 
                      rightChecked={rightVal === this.state.score} 
                      starClass={starClass} 
                      checked={checked}/>;
  }

  render() {
    return (
      <div>
        <fieldset className="stars-container">
          {this.renderStar('4.5', '_4_5', '5.0', '_5_0', 'fas', false)}
          {this.renderStar('3.5', '_3_5', '4.0', '_4_0', 'fas', false)}
          {this.renderStar('2.5', '_2_5', '3.0', '_3_0', 'fas', false)}
          {this.renderStar('1.5', '_1_5', '2.0', '_2_0', 'fas', false)}
          {this.renderStar('0.5', '_0_5', '1.0', '_1_0', 'fas', false)}
          {this.renderStar('0.0', '_0_0_1', '0.0', '_0_0_2', 'empty-star far', true)}
        </fieldset>
        <p className="temp-score">
          <b>{this.state.tempScore}</b>
        </p>
      </div>
    );
  }
}

export default ScoreStarList;