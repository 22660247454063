import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ScoreComponent from './ScoreComponent';
// css imports
import './ScoreList.css';

class ScoreList extends ScoreComponent {
  render() {
    return (
      <Select className="score-select"
              value={this.state.score}
              onChange={(event) => this.updateScore(event.target.value)}>
        <MenuItem value={'0.0'}>0.0</MenuItem>
        <MenuItem value={'0.5'}>0.5</MenuItem>
        <MenuItem value={'1.0'}>1.0</MenuItem>
        <MenuItem value={'1.5'}>1.5</MenuItem>
        <MenuItem value={'2.0'}>2.0</MenuItem>
        <MenuItem value={'2.5'}>2.5</MenuItem>
        <MenuItem value={'3.0'}>3.0</MenuItem>
        <MenuItem value={'3.5'}>3.5</MenuItem>
        <MenuItem value={'4.0'}>4.0</MenuItem>
        <MenuItem value={'4.5'}>4.5</MenuItem>
        <MenuItem value={'5.0'}>5.0</MenuItem>        
      </Select>
    );
  };
}

export default ScoreList;