import { Component } from 'react';

class ScoreComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      score: this.props.startScore
    };
  }
  
  updateScore(newScore) {
    this.setState({
      score: newScore
    });
    this.props.scoreListener(newScore);
  }
}

export default ScoreComponent;