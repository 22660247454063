import React, { Component, Fragment } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import './NotFound.css';

class NotFound extends Component {
  render() {
    return (
      <Fragment>
        <AppBar position="static" color="primary">
          <Toolbar>
            <Typography variant="h6" color="inherit">
              RCO - RED VIACORTA
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="main-container">
          <p className="not-found">
            <FormattedMessage 
            id="not_found" 
            defaultMessage="Not found"/>
          </p>
        </div>
      </Fragment>
    );
  }
}

export default NotFound;