import React, { Component, Fragment } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ScoreList from './ScoreList';
import ScoreStarList from './ScoreStarList';
import ScoreComments from './ScoreComments';
import ScoreResultModal from './ScoreResultModal';
import ScoreFooter from './ScoreFooter';

// css
import './ScoreBoard.css';
// react-intl imports
import { injectIntl, FormattedMessage } from 'react-intl';

class ScoreBoard extends Component {
  constructor(props) {
    super(props);
    this.data = new URLSearchParams(props.location.search).get('data');
    this.state = {
      score: "0.0",
      tempScore: "0.0",
      comment: '',
      resultModalShow: false,
      resultModalText: '',
      resultModalLoading: false,
      windowWidth: window.innerWidth,
      showThankYouMessage: false
    };
  }

  handleWindowSizeChange = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  setTempScore(tempScore) {
    this.setState({
      tempScore: tempScore
    });
  }

  resetTempScore() {
    this.setState({
      tempScore: this.state.score
    });
  }

  setScore(score) {
    this.setState({
      score: score
    });
  }

  getScore() {
    return this.state.score;
  }

  showResultModal(show) {
    this.setState({
     resultModalShow: show
    });
  }

  setResultModalText(text) {
    this.setState({
      resultModalText: text
    });
  }

  setResultModalLoading(loading) {
    this.setState({
      resultModalLoading: loading
    });
  }

  setComment(comment) {
    this.setState({
      comment: comment
    });
  }

  getSanitizedComment() {
    return this.state.comment.replace(/[\r+\n+\s\s+]+/g, ' ').trim();
  }

  showThankYouMessage(show) {
    this.setState({
      showThankYouMessage: show
    });
  }

  submitScore() {
    const { formatMessage } = this.props.intl;
    const comment = this.getSanitizedComment();

    if (comment.length > 512) {
      this.setResultModalText(formatMessage({id: 'comment_max_length'}));  
      this.showResultModal(true);
      return;
    }
    const encodedData = encodeURIComponent(this.data).replace(/%20/g,'+');
    let urlQueryParamsString = '?data=' + encodedData + 
    '&clientScore=' + this.getScore();

    if (comment.length > 0) {
      urlQueryParamsString += '&clientComment=' + encodeURIComponent(comment);
    }
    this.setResultModalText(formatMessage({id: 'modal_sending_score'}));
    this.setResultModalLoading(true);
    this.showResultModal(true);
    fetch(
      process.env.GIS_API_URL + urlQueryParamsString
    )
    .then((res) => {
      this.setResultModalText(res.status === 200 ? 
        formatMessage({id: 'modal_success_sending_score'}) : 
        formatMessage({id: 'modal_error_sending_score'})
      );
      this.setResultModalLoading(false);

      if (res.status === 200) {
        this.showThankYouMessage(true);
      }
    }, (err) => {
      console.log(err);
      this.setResultModalText(formatMessage({id: 'modal_error_sending_score'}));
      this.setResultModalLoading(false);
    });
  }

  render() {
    const { windowWidth } = this.state;
    const isMobile = windowWidth <= 500;
    let scoreComponent;

    if (isMobile) {
      scoreComponent = <ScoreList startScore={this.state.score} 
                         scoreListener={this.setScore.bind(this)} />;
    } else {
      scoreComponent = <ScoreStarList startScore={this.state.score} 
                         scoreListener={this.setScore.bind(this)} 
                         getScore={this.getScore.bind(this)}/>;
    }
    return (
    <Fragment>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography variant="h6" color="inherit">
            <FormattedMessage 
              id="toolbar_title"
              defaultMessage="© RCO - RED VIA CORTA CLIENT SERVICE SCORE" />
          </Typography>
        </Toolbar>
      </AppBar>
        {
          this.state.showThankYouMessage ? (
            <div className="container">
              <span className="thank-you-label">
                <FormattedMessage 
                  id="thank_you_label" 
                  defaultMessage="Share your opinion" />
              </span>
            </div>
          ) : (
            <div className="container">
              <div className="status">
                <FormattedMessage 
                  id="score_status" 
                  defaultMessage={`Click submit to send your score (defaultMessage)`} 
                />
              </div>
              {scoreComponent}
              <ScoreComments commentListener={this.setComment.bind(this)}/>
              <Button className="btn-submit"        
                color="secondary" 
                variant="contained" 
                onClick={() => this.submitScore()}>
                <FormattedMessage 
                  id="submit_score" 
                  defaultMessage="Submit"
                />
              </Button>
            </div>
          )
        }
      <ScoreFooter />
      <ScoreResultModal 
        show={this.state.resultModalShow} 
        text={this.state.resultModalText} 
        loading={this.state.resultModalLoading}
        img={this.state.resultModalImg}  
        showResultModal={this.showResultModal.bind(this)} />
    </Fragment>
    );
  }
}

export default injectIntl(ScoreBoard);